import dynamic from "next/dynamic";
import styles from "./header.module.scss";

const Topbar = dynamic(() => import("./Topbar"));
const DesktopNavbar = dynamic(() => import("./DesktopNavbar"));
const MobileNavbar = dynamic(() => import("./MobileNavbar"));

const Header = ({ header_menu = [] , isRajasthan=false , isMadhya=false, isHR = false, isUP = false, isBH=false,isCGH=false}) => {
  return (
    <>
      <div className="hidden md:block">
        <Topbar isRajasthan={isRajasthan} isMadhya={isMadhya} isHR={isHR} isBH={isBH} isUP={isUP} isCGH={isCGH}  />
      </div>
      <header className={`${styles.headers}`}>
        <div className="block sticky md:hidden">
          <MobileNavbar header_menu={header_menu} />
        </div>
        <div className="hidden md:block">
          <DesktopNavbar />
        </div>
      </header>
    </>
  );
};

export default Header;
